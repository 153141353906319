







import { Component, Prop, Vue } from "vue-property-decorator";
import { UserAgent } from "./UserAgent";
import EmbedView from "./EmbedView.vue";
import IframeView from "./IframeView.vue";
import ObjcetView from "./ObjectView.vue";
import PdfViewer from "./PdfViewer.vue";

const ViewerType = {
    embeded: "embeded",
    pdfjs: "pdfjs",
    iframe: "iframe",
    pdfObject: "pdfObject"
} as const;

type PDFViewerType = typeof ViewerType[keyof typeof ViewerType];

@Component({
    components: {
        EmbedView, IframeView, ObjcetView, PdfViewer
    }
})
export default class PreviewPdfView extends Vue {
    name: string = "preview-pdf-view";
    ua: UserAgent = new UserAgent();

    @Prop({ default: '' })
    readonly id!: string;

    @Prop({ default: '' })
    readonly file!: string;

    @Prop({ default: false })
    readonly saveAllowed!: boolean;

    @Prop({ default: false })
    readonly isMobile!: boolean;

    get viewerType(): PDFViewerType {
        const browserType = this.ua.getBrowserType();
        if(browserType == "Electron" && this.saveAllowed) {
            // Electron & DL可
            return "embeded";
        } else if(browserType == "IE" || browserType == "Edge" || !this.saveAllowed || this.isMobile ) {
            // IE or Edge or DL不可 or モバイルアプリ
            return "pdfjs";
        } else if(browserType == "Firefox") {
            return "embeded";
        } else if(browserType == "Safari") {
            return "iframe";
        } else {
            return "pdfObject";
        }
    }

    created(): void {
        this.ua = new UserAgent();
        console.log(this.ua);
    }



}
