export class UserAgent {
    private parsedUserAgent: ParsedUserAgent;

    public constructor() {
        const rawUa = window.navigator.userAgent;
        this.parsedUserAgent = new ParsedUserAgent(rawUa);
        console.log(this.parsedUserAgent);
    }

    public getBrowserType(): BrowserType {
        return this.parsedUserAgent.browserType;
    }
}

class ParsedUserAgent {
    private ELECTRON_VERSION_MATCHER: RegExp = /direct\/([0-9]+)\.([0-9]+)\.([0-9]+)/g;

    public mac: boolean;
    public win7: boolean;
    public browserType: BrowserType;

    public constructor(rawUa: string) {
        const ua = rawUa.toLowerCase();
        this.mac = ua.indexOf("mac") != -1;
        this.win7 = !!ua.match(/win(dows )?nt 6\.1/);
    
        if(ua.match(this.ELECTRON_VERSION_MATCHER)) {
            this.browserType = "Electron";
        } else if(ua.indexOf('msie') != -1 || ua.indexOf('trident') != -1) {
            this.browserType = "IE";
        } else if(ua.indexOf('edge') > -1) {
            this.browserType = "Edge";
        } else if(ua.indexOf('chrome') > -1) {
            this.browserType = "Chrome";
        } else if(ua.indexOf('safari') > -1) {
            this.browserType = "Safari";
        } else if (ua.indexOf('firefox') != -1) {
            this.browserType = "Firefox";
        } else {
            this.browserType = "Unknown";
        }
    }
}


const BrowserType = {
    Electron: 'Electron',
    Chrome: 'Chrome',
    Firefox: 'Firefox',
    IE: 'IE',
    Edge: 'Edge',
    Safari: 'Safari',
    Unknown: 'Unknown',
} as const;

type BrowserType = typeof BrowserType[keyof typeof BrowserType]