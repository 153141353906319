






























import { Component, Prop, Vue } from "vue-property-decorator";
import { RenderedPage } from "./rendered-page";
import { PDFDocumentProxy } from "pdfjs-dist";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let pdfjsLib: any = undefined;

@Component({})
export default class PdfViewer extends Vue {
    name: string = "pdf-viewer";
    pdf: PDFDocumentProxy|undefined = undefined;
    numPages: number = 0;
    currentPage: number = 1;
    scale: number = 1.0;
    renderedPage: RenderedPage = new RenderedPage();

    isFirstLoad: boolean = true;
    maxW: number = 0;

    rendering: boolean = false

    @Prop({ default: '' })
    readonly id!: string;

    @Prop({ default: '' })
    readonly file!: string;

    @Prop({ default: '' })
    readonly width!: string;

    @Prop({ default: 0 })
    readonly height!: number;

    getCanvasId(pageIndex: number): string {
        return `canvas-${pageIndex+1}-${this.id}`;
    }

    async created() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if( !pdfjsLib ) pdfjsLib = (globalThis as any)["pdfjs-dist/build/pdf"];
    }

    async mounted(): Promise<void> {
        const ref = this.$refs.pdf as HTMLElement;
        if( ref ) this.maxW = ref.offsetWidth;
        await this.loadPDF();
        await this.renderPages();
    }

    async loadPDF(): Promise<void> {
        const PDFJS = pdfjsLib.PDFJS;
        /* pdf-distを配置した場所へのパス */
        PDFJS.workerSrc = '/lib/js/pdfjs-dist/build/pdf.worker.js';
        PDFJS.cMapUrl = '/lib/js/pdfjs-dist/web/cmaps/';
        PDFJS.cMapPacked = true;
        PDFJS.isEvalSupported = false; // pdfjsの脆弱性回避

        try {
            const pdf: PDFDocumentProxy = await PDFJS.getDocument(this.file).promise;

            this.pdf = pdf;
            this.numPages = pdf.numPages;
        } catch (e) {
            this.pdf = undefined;
        }
    }

    async renderPages(): Promise<void> {
        this._renderPages()
            .catch((e) => {
                console.log("_renderPages error:", e);
                this.rendering = false;
            })
    }

    async _renderPages(): Promise<void> {
        // レンダリング中はZoomIn,ZoomOutボタンをdisabledへ
        this.rendering = true
        const first = Promise.resolve();
        let before = first;
        for(let i = 0; i < this.numPages; i++) {
            before = before.then(this.renderPage.bind(this, i));
        }
        before
            .then(() => {
                console.log("render complete", this.scale);
            })
            .catch((e: any) => {
                console.log("An error occurred during rendering.", e)
            })
            .finally(() => {
                this.rendering = false
            });
    }

    async renderPage(pageIndex: number): Promise<void> {
        const page = await this.pdf?.getPage(pageIndex+1);
        if( !page || this.renderedPage.exists(page)) {
            return;
        }

        if(this.isFirstLoad) {
            // 最初表示のみ(拡大縮小押すまで): scaleを幅の約80%に合わせに行く
            const origin = page.getViewport(1.0);
            const scale = (this.maxW * 0.80) / origin.width;
            this.scale = scale;
        }
        const viewport = page.getViewport(this.scale);

        const canvas = this.assureCanvas(pageIndex);

        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const ctx = canvas.getContext('2d');
        if(ctx === null) return;

        const renderingTask = page.render({
            viewport: viewport,
            canvasContext: ctx
        });
        this.renderedPage.add(page);

        await renderingTask;
    }

    assureCanvas(pageIndex: number): HTMLCanvasElement {
        const canvas = document.getElementById(this.getCanvasId(pageIndex))
        if (canvas instanceof HTMLCanvasElement) {
            return canvas
        }
        const newCanvas = document.createElement('canvas')
        newCanvas.setAttribute('id', this.getCanvasId(pageIndex))
        const pageDiv = document.getElementById('page-' + (pageIndex + 1))
        if(pageDiv) {
            pageDiv.appendChild(newCanvas)
        }
        return newCanvas
    }

    updateScale(newScale: number): void {
        this.scale = newScale;
        this.renderedPage.clear();
        this.renderPages();
    }

    zoomIn(): void {
        if(this.isFirstLoad) {
            // (n * 0.25)の近似値にscale変換
            const nearScale = Math.round(this.scale/0.25) * 0.25;
            this.scale = nearScale;
            this.isFirstLoad = false;
        }
        this.updateScale(Math.min(this.scale + 0.25, 4.0));
    }

    zoomOut(): void {
        if(this.isFirstLoad) {
            // (n * 0.25)の近似値にscale変換
            const nearScale = Math.round(this.scale/0.25) * 0.25;
            this.scale = nearScale;
            this.isFirstLoad = false;
        }
        this.updateScale(Math.max(this.scale - 0.25, 0.1));
    }
}
