




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ObjcetView extends Vue {
    name: string = 'object-view';
    
    @Prop({ default: '' })
    readonly file!: string;
}
