




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class EmbedView extends Vue {
    name: string = "embed-view";

    @Prop({ default: "" })
    readonly file!: string;
}
