import { PDFPageProxy } from "pdfjs-dist";
export class RenderedPage {
    renderedPagesCache: Map<string, boolean>;

    constructor() {
        this.renderedPagesCache = new Map();
    }

    add(page:PDFPageProxy): void {
        const pageIndex = page.pageNumber -1;
        this.renderedPagesCache.set(pageIndex.toString(), true);
    }

    exists(page:PDFPageProxy): boolean {
        const pageIndex = page.pageNumber -1;
        return !!this.renderedPagesCache.get(pageIndex.toString());
    }
    clear(): void {
        this.renderedPagesCache = new Map();
    }
}
